<template>
  <div class="">
    <div class="nav_bar" style="margin-top: 20px">
      <span class="card">印象{{dtail.region_name.re_name}}</span>
    </div>
    <div class="impression">
      <div class="left">
        <div class="box" @click="impressionList(item)" v-for="(item, index) in dtail.impression" :key="item.id">
          <van-image
            fit="cover"
            class="imgs"
            radius="10"
            :src="item.impression_img"
          />
          <span v-if="item.impression_cate==1">地区简介</span>
          <span v-else-if="item.impression_cate==2">招商优势</span>
          <span v-else-if="item.impression_cate==7">宣传手册</span>
          <span v-else-if="item.impression_cate==8">魅力{{ dtail.region_name.re_name}}</span>
        </div>
      </div>
      <!-- <div class="right">
        <div style="height: 12.5rem">
          <div class="title">
            手册
            <div class="more">显示更多+</div>
          </div>
          <ul>
            <li v-for="item in 4">
              <span>●</span>
              我市城市建设项目投融资改革成效初显投融资投入项目已完成9.5亿元
            </li>
          </ul>
        </div>
        <div style="height: 12rem">
          <div class="title">
            风貌
            <div class="more">显示更多+</div>
          </div>
          <ul>
            <li v-for="item in 4">
              <span>●</span>
              我市城市建设项目投融资改革成效初显投融资投入项目已完成9.5亿元阿萨法撒
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
export default {
  name: "Vue",
  props: ['dtail','code'],
  data() {
    return {};
  },
  components: {
    [VanImage.name]: VanImage,
  },
  computed: {},

  created() {
  },

  methods: {
    impressionList(e){
      if(e.impression_cate == 1){
        this.$router.push({
          name: 'ImpressionDetail',
          params:{
            type: 1,
            code: this.code
          }
        })
      }else if(e.impression_cate == 2){
        this.$router.push({
          name: 'More',
          params:{
            type: 3,
            code: this.code
          }
        })
      }else if(e.impression_cate == 7){
        this.$router.push({
          name: 'More',
          params:{
            type: 4,
            code: this.code
          }
        })
      }else if(e.impression_cate == 8){
        this.$router.push({
          name: 'More',
          params:{
            type: 5,
            code: this.code
          }
        })
      }
    },
  },
};
</script>
<style lang='less' scoped>
// 印象板块样式
.impression {
  display: flex;
  .left {
    // width: 40%;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 6%;
    .box {
      text-align: center;
      width: 49%;
      cursor: pointer;
      .imgs {
        width: 100%;
        height: 10rem;
      }
      span {
        font-size: 1rem;
      }
    }
    .box {
      margin-bottom: 12px;
    }
  }
  .right {
    width: 60%;
    margin-left: 2%;
    .title {
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      background-color: #f4f4f4;
      position: relative;
      .more {
        position: absolute;
        right: 10px;
      }
    }
    ul {
      li {
        line-height: 40px;
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
</style>