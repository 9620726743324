<template>
<div class="bj" v-wechat-title="homeIndex.region_name? '皖云投 | ' + homeIndex.region_name.now_name:'招商平台'">
  <div class="home box_media">
    <!-- 轮播图 -->
    <div class="container_swiper">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="item in homeIndex.slide_img" :key="item.id">
          <van-image fit="cover" class="swiper_me" :src="item.ImgUrls" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="homeTitle" v-if="homeIndex.region_name">
      {{ homeIndex.region_name.now_name }}
    </div>
    <div class="homeTitle" @click="toPhone" style="font-size:1rem;color:#666;cursor: pointer;" v-if="homeIndex.region_name">
      招商热线 {{ homeIndex.region_name.invest_phone }}
    </div>
    <div class="center">
      <div class="videoANDnews">
       <!-- 宣传片+投资动态 -->
      <div
        class="container_news_video"
        v-if="homeIndex.region_name && homeIndex.region_name.seed_video"
      >
        <div class="coverPicture">
          <van-image
            fit="cover"
            class="imgs"
            :src="
              homeIndex.region_name.seed_video +
              '?x-oss-process=video/snapshot,t_0,f_jpg'
            "
          />
          <div class="title">{{ homeIndex.region_name.RegionName }}宣传片</div>
          <img
            class="btn_bofang"
            @click="videoShowClick"
            src="http://yuyok.oss-cn-hangzhou.aliyuncs.com/storage/storage1/image/icon/bofang.png"
            alt=""
          />
        </div>
        <video
          autoplay
          v-if="videoShow"
          :src="homeIndex.region_name.seed_video"
          controls
        ></video>
      </div>
      <!-- 投资动态 -->
      <div class="news_box">
        <div class="nav_bar">
          <span class="card">投资动态</span>
          <div class="more" @click="more(1)">显示更多</div>
          <!-- <div class="nav_list">
            <span class="active">领导</span>
            <span>活动</span>
            <span>项目</span>
          </div> -->
        </div>
        <!-- 动态列表 -->
        <div class="news_list">
          <van-tabs color="#35a3bd" @change="onNewsChange" v-model="newsIndex">
            <van-tab v-for="(item, index) in news" :title="item" :key="index">
              <ul>
                <li
                  @click="toNews(item)"
                  v-for="item in newsList[index].list"
                  :key="item.id"
                >
                  <span>●</span>
                  {{ item.news_name }}
                </li>
              </ul>
              <!-- <div class="more">显示更多</div> -->
            </van-tab>
          </van-tabs>
        </div>
      </div>
      </div>
       <!-- 印象板块 -->
      <impression v-if="homeIndex.impression" :dtail="homeIndex" :code="code" />
      <!-- 投资地图封面图 -->
      <div class="nav_bar" style="margin-top: 20px">
        <span class="card">投资地图</span>
      </div>
      <van-image
        fit="cover"
        @click="toMap"
        style="width: 100%; height: 16rem; margin: 10px 0;cursor: pointer;"
        :src="touziDetail.invest_cate_img"
      />
      <!-- 投资板块 -->
      <invest-ment @toproject="toproject" :list="catalogueList" @listLodings="listLodings" />
      <!-- 政策板块 -->
      <div class="nav_bar" style="margin-top: 20px">
        <span class="card">招商政策</span>
        <div class="more" @click="more(6)">显示更多</div>
      </div>
      <div class="policy">
        <van-tabs color="#35a3bd" @change="policyIndexChange" v-model="policyIndex">
          <van-tab
            v-for="(item, index) in policyList"
            :title="item.ser_name"
            :key="item.id"
          >
            <ul>
              <li @click="topolicyDetail(item)" v-for="item in policyOnList[index].list" :key="item.id">
                <span>●</span>
                {{item.news_name}}
              </li>
            </ul>
            <!-- <div class="more">显示更多</div> -->
          </van-tab>
        </van-tabs>
      </div>
      <!-- 重点产业链 -->
      <div class="nav_bar" style="margin-top: 20px">
        <span class="card">重点产业链</span>
      </div>
      <div class="industrial_chain">
        <div class="industrial_chain_list" @click="toLndustrial_chain(item)" v-for="item in homeIndex.industrialChain" :key="item.id">
          <span>{{item.industrial_chain_name}}</span>
          <van-image
            fit="cover"
            class="imgs"
            :src="item.industrial_chain_img"
          />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {
  appindex,
  newsheader,
  newsLists,
  invest_cate_img,
  project_list,
  servervice,
  server_cates_one,
} from "@/api/http";
import { Swipe, SwipeItem, Image as VanImage, Button, Tab, Tabs } from "vant";
import investMent from "@/components/investment/investment";
import impression from "@/components/impression/impression";
export default {
  name: "Home",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    investMent,
    impression,
  },
  data() {
    return {
      videoShow: false,
      newsIndex: 0,
      code: "156",
      news: [],
      newsList: [],
      homeIndex: {},
      touziDetail: {},
      url: [
        "gardenlist",
        "landlist",
        "workslist",
        "itemslist",
        "buildlist",
        "masslist",
        "more_enterprise",
        "more_business",
      ],
      catalogueList: [],
      policyList: [],
      policyOnList: [],
      policyIndex: 0,
    };
  },
  methods: {
    //显示更多
    more(e){
      this.$router.push({
        name: 'More',
        params:{
          code: this.code,
          type: e
        }
      })
    },
    //点击宣传片播放视频
    videoShowClick() {
      this.videoShow = true;
    },
    //去投资动态详情页
    toNews(item) {
      let type = item.news_type;
      this.$router.push({
        name: "NewsDetail",
        params: {
          id: item.id,
          type: type,
        },
      });
    },
    //投资动态事件
    onNewsChange(index, e) {
      this.newsIndex = index;
      //有数据就不调用接口
      if (this.newsList[this.newsIndex].list.length > 0) {
        return;
      }
      this.newsLoding();
    },
    //去详情页
    toMap() {
      this.$router.push({
        name: "Map",
        params: {
          code: this.code,
        },
      });
    },
    //投资动态列表接口
    newsLoding() {
      let cate = 0;
      if (this.newsIndex == 0) {
        cate = 1;
      } else if (this.newsIndex == 1) {
        cate = 2;
      } else if (this.newsIndex == 2) {
        cate = 3;
      } else if (this.newsIndex == 3) {
        cate = 4;
      }
      newsLists({
        region_code: this.code,
        belong_cate: cate,
        page: 1,
      }).then((res) => {
        let arr = res.data.Newsinfo.data;
        if (arr.length > 0) {
          arr.forEach((v, index) => {
            if (index < 6) {
              this.newsList[this.newsIndex].list.push(v);
            }
          });
        }
      });
    },
    listLodings(e, i) {
      if (this.catalogueList[i].list.length == 0) {
        this.touziLoding(i);
      }
    },
    toproject(e,type){
      this.$router.push({
        name: type,
        params:{
          code:this.code,
          id: e.id
        }
      })
    },
    //打电话
    toPhone(){
      window.location.href = 'tel:'+ this.homeIndex.region_name.invest_phone
    },
    //投资项目接口
    touziLoding(e) {
      this.polygons = [];
      project_list(
        {
          region_code: this.code,
          page: 1,
        },
        this.url[e]
      ).then((res) => {
        var data = [];
        if (e == 0) {
          data = res.data.gardens.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.garden_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 1) {
          data = res.data.land.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.land_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 2) {
          data = res.data.workslist.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.works_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 3) {
          data = res.data.itemslists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.items_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 4) {
          data = res.data.buildlists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.build_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 5) {
          data = res.data.masslists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.mass_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 6) {
          data = res.data.famousen.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.enterprises_name,
              });
            });
          }
        } else if (e == 7) {
          data = res.data.business.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              if (index >= 6) return;
              this.catalogueList[e].list.push({
                id: v.id,
                name: v.business_name,
              });
            });
          }
        }
      });
    },
    //投资分类
    touFL() {
      servervice({
        region_code: this.code,
        ser_type: 1,
        page: 1,
      }).then((res) => {
        let arr = res.data.find_servervices.data;
        if (arr.length > 0) {
          this.policyList = res.data.find_servervices.data;
          this.policyList.forEach((v,index)=>{
            this.policyOnList.push({
              id: index,
              page: 1,
              list: []
            })
          })
          this.touFLList(this.policyList[0].id)
        }
      });
    },
    //政策轮播图事件
    policyIndexChange(i,e){
      if(this.policyOnList[i].list.length!=0)return
      this.touFLList(this.policyList[i].id)
    },
    //分类列表
    touFLList(e) {
      server_cates_one({
        id: e,
        ser_type: 1,
        page: 1,
      }).then((res) => {
        this.policyOnList[this.policyIndex].list = res.data.server_data.data
      });
    },
    //去政策详情页
    topolicyDetail(item){
      this.$router.push({
        name: 'TwDetail',
        params: {
          code: this.code,
          cate: item.id,
          type: 2
        }
      })
    },
    //去产业链详情
    toLndustrial_chain(item){
      this.$router.push({
        name: 'IndustrialChain',
        params:{
          code: this.code,
          id: item.id,
          titles:item.industrial_chain_name
        }
      })
    }
  },
  created() {
    this.code = this.$route.params.code;
    for (let i = 0; i < 8; i++) {
      this.catalogueList.push({
        id: i,
        page: 1,
        list: [],
      });
    }
    appindex({
      region_code: this.code,
    }).then((res) => {
      this.homeIndex = res.data.data;
      localStorage.setItem(
        "region_name",
        JSON.stringify(res.data.data.region_name)
      );
      invest_cate_img({
        region_code: this.code,
      }).then((res) => {
        this.touziDetail = res.data.region_name;
      });
    });
    newsheader().then((res) => {
      this.news = res.data.region_new;
      //换位置
      // let arr = "";
      // arr = this.news[1];
      // this.news.splice(1, 1);
      // this.news.push(arr);
      this.news.forEach((v) => {
        this.newsList.push({
          list: [],
        });
      });
      this.newsLoding();
    });
    //投资项目接口
    this.touziLoding(0);
    this.touziLoding(2);
    this.touziLoding(4);
    this.touziLoding(6);

    //投资分类接口
    this.touFL();
  },
};
</script>
<style lang="less" scoped>

.bj{
  background-color: #f6f6f6;
}
.home {
  background-color: #fff;
  .homeTitle {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
  }
  .container_swiper {
    position: relative;
    .title {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      z-index: 99;
      font-weight: bold;
      color: #35a3bd;
      font-size: 1.5rem;
      text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
    }
    .swiper_me {
      width: 100%;
      height: 18rem;
    }
  }
  .center {
    padding: 10px;
    .container_news_video {
      position: relative;
      .coverPicture {
        position: relative;
        .title {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          color: #fff;
          padding: 1rem;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 1rem;
        }
        .imgs {
          width: 100%;
          height: 20rem;
        }
        .btn_bofang {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 40%;
        }
      }
      video {
        width: 100%;
        height: 100%;
        background-color: #000;
        position: absolute;
        top: 0;
      }
    }
    .news_list,
    .investment_list,
    .policy {
      ul {
        li {
          margin-left: 1rem;
          list-style: disc;
          line-height: 40px;
          font-size: 1rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          cursor: pointer;
        }
      }
    }
    // 重点产业链
    .industrial_chain {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .industrial_chain_list {
        width: 48%;
        background-color: #def9ff;
        position: relative;
        height: 8rem;
        border-radius: 10px;
        margin-bottom: 10px;
        .imgs {
          width: 50%;
          height: 100%;
          position: absolute;
          left: 0;
        }
        span {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 20px;
          font-weight: bold;
          font-size: 1.2rem;
          z-index: 9;
        }
      }
    }
  }
}
</style>
