<!--  -->
<template>
  <div class="investment">
    <div class="nav_bar" style="margin-top: 20px">
      <span class="card">投资项目</span>
      <div class="more" @click="more(2)">显示更多</div>
    </div>
    <div class="investment_list">
      <div class="nav_bar">
        <div class="nav_list">
          <span
            @click="list1Click(index)"
            v-for="(item, index) in list1"
            :key="index"
            :class="list1Index == index ? 'active' : ''"
            >{{ item }}</span
          >
        </div>
      </div>
      <ul v-if="list1Index == 0">
        <li
          @click="toDetail(item, 0)"
          v-for="item in lists[0].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <ul v-else>
        <li
          @click="toDetail(item, 1)"
          v-for="item in lists[1].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <!-- <div class="more">显示更多</div> -->
    </div>
    <div class="investment_list">
      <div class="nav_bar">
        <div class="nav_list">
          <span
            @click="list2Click(index)"
            v-for="(item, index) in list2"
            :key="index"
            :class="list2Index == index ? 'active' : ''"
            >{{ item }}</span
          >
        </div>
      </div>
      <ul v-if="list2Index == 0">
        <li
          @click="toDetail(item, 2)"
          v-for="item in lists[2].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <ul v-else>
        <li
          @click="toDetail(item, 3)"
          v-for="item in lists[3].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="investment_list">
      <div class="nav_bar">
        <div class="nav_list">
          <span
            @click="list3Click(index)"
            v-for="(item, index) in list3"
            :key="index"
            :class="list3Index == index ? 'active' : ''"
            >{{ item }}</span
          >
        </div>
      </div>
      <ul v-if="list3Index == 0">
        <li
          @click="toDetail(item, 4)"
          v-for="item in lists[4].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <ul v-else>
        <li
          @click="toDetail(item, 5)"
          v-for="item in lists[5].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <!-- <div class="more">显示更多</div> -->
    </div>
    <div class="investment_list">
      <div class="nav_bar">
        <div class="nav_list">
          <span
            @click="list4Click(index)"
            v-for="(item, index) in list4"
            :key="index"
            :class="list4Index == index ? 'active' : ''"
            >{{ item }}</span
          >
        </div>
      </div>
      <ul v-if="list4Index == 0">
        <li
          @click="toDetail(item, 6)"
          v-for="item in lists[6].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <ul v-else>
        <li
          @click="toDetail(item, 7)"
          v-for="item in lists[7].list"
          :key="item.id"
        >
          <span>●</span>
          {{ item.name }}
        </li>
      </ul>
      <!-- <div class="more">显示更多</div> -->
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
export default {
  name: "Vue",
  props: ["list"],
  data() {
    return {
      list1: ["园区", "地块"],
      list2: ["厂房", "项目"],
      list3: ["楼宇", "众创"],
      list4: ["名企", "商协会"],
      list1Index: 0,
      list2Index: 0,
      list3Index: 0,
      list4Index: 0,
      lists: [],
    };
  },

  components: {
    [Button.name]: Button,
  },

  created() {
    this.lists = this.list;
  },

  methods: {
    list1Click(index) {
      this.list1Index = index;
      this.$emit("listLodings", 1, index);
    },
    list2Click(index) {
      this.list2Index = index;
      this.$emit("listLodings", 2, index + 2);
    },
    list3Click(index) {
      this.list3Index = index;
      this.$emit("listLodings", 3, index + 4);
    },
    list4Click(index) {
      this.list4Index = index;
      this.$emit("listLodings", 4, index + 6);
    },
    toDetail(item, i) {
      let arr = [
        "Park",
        "Massif",
        "Workshop",
        "Project",
        "Building",
        "PopularEn",
        "FamousEnterprises",
        "BusinessAssociation",
      ];
      this.$emit("toproject", item, arr[i]);
    },
    //显示更多
    more(e){
      this.$router.push({
        name: 'More',
        params:{
          code: this.$route.params.code,
          type: e
        }
      })
    },
  },
};
</script>
<style lang='less' scoped>
.nav_bar {
  background-color: #f4f4f4;
  line-height: 44px;
  margin: 10px 0;
  .card {
    display: inline-block;
    padding: 0 1rem;
    height: 100%;
    background-color: #35a3bd;
    color: #fff;
    font-weight: bold;
    font-size: .8rem;
  }
  .nav_list {
    margin-left: 5%;
    display: inline-block;
    span {
      display: inline-block;
      padding: 0 1rem;
      height: 100%;
      font-weight: bold;
      font-size: 0.175rem;
    }
    .active {
      background-color: #35a3bd;
      color: #fff;
    }
  }
}
.news_list,
.investment_list {
  ul {
    li {
      margin-left: 1rem;
      list-style: disc;
      line-height: 40px;
      font-size: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      cursor: pointer;
    }
  }
}
.investment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  .investment_list {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  .nav_list {
    margin: 0;
    display: flex;
    cursor: pointer;
    span {
      flex: 1;
      text-align: center;
    }
  }
  .nav_bar {
    width: 100%;
  }
}
</style>